.transitionItem {
  transition: opacity 0.8s ease-out;
  opacity: 0;
  visibility: hidden;
}
.transitionItemActive {
  opacity: 1;
  visibility: visible;
}

.tropyTitle {
  padding-left: 18%;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}
.trophyContainer {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 75%;
}

.centerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
