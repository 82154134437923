/*
===== Brand Colours =====
*/
:root {
    --pyschosocial: #f2c94d;
    --physical: #94fda2;
    --royal-blue: #3b00b8;
}

/* 
===== Dashboard =====
*/
.toggle-dashboard-view-buttons {
    padding: 0px 16px 16px 16px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 30px;
}
.toggle-dashboard-view-buttons > label {
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid #5e72e4;
}
.toggle-dashboard-view-buttons > label:hover {
    z-index: 0 !important;
    background-color: var(--royal-blue) !important;
}
.toggle-dashboard-view-buttons input[type='radio'] {
   display: none; 
}
.toggle-dashboard-view-button-unselected {
    border: 1px solid #5e72e4;
    background-color: transparent !important;
    color: #5e72e4;
}

/* 
===== User Timeline =====
*/

.timeline {
    position: relative;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    border-right: 2px dashed #e9ecef;
}

.timeline-block {
    position: relative;
    margin: 1em 0 0 0;
}

.timeline-block:after {
    content: "";
    display: table;
    clear: both;
}
.timeline-block:first-child {
    margin-top: 0;
}

.timeline-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
}

.timeline-one-side .timeline-step, .timeline-one-side:before {
    left: 1rem;
}

.timeline-content {
    margin-left: 60px;
    padding-top: 0.5rem;
    position: relative;
    top: -6px;
}

.timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

.timeline .badge-success    { background-color: #c9f3e1; }
.timeline .badge-info       { background-color: #c3f2fb; }
.timeline .badge-warning    { background-color: #FEE4DE; }
.timeline .badge-danger     { background-color: #fdd7de; }

/*
===== Usage Heatmap =====
*/

.usage-heatmap-empty { fill: #eeeeee; }
.usage-heatmap-0 {fill: #ffffff; }
.usage-heatmap-1 {fill: #CCE6BD; }
.usage-heatmap-2 {fill: #B8DEAA; }
.usage-heatmap-3 { fill: #A1D698; }
.usage-heatmap-4 { fill: #88CE86; }
.usage-heatmap-5 {fill: #74C67A; }
.usage-heatmap-6 { fill: #62BE73; }
.usage-heatmap-7 {fill: #50B56E; }
.usage-heatmap-8 { fill: #3FAC6C; }
.usage-heatmap-9 { fill: #2EA36B; }
.usage-heatmap-10 { fill: #1D9A6C; }
.usage-heatmap-11 { fill: #FFE355; }
.usage-heatmap-12 { fill: #FFC240; }
.usage-heatmap-13 { fill: #FF9F2B; }
.usage-heatmap-14 { fill: #FF7D15; }
.usage-heatmap-15 { fill: #FF5B00; }
.usage-heatmap-16 { fill: #8F0B00; }

.usage-heatmap-scale > *:not(:last-child) {
    margin-right: 2px; 
}

.usage-heatmap-scale small {
    margin-right: 0.25rem; 
} 
.usage-heatmap-period-dropdown .dropdown-toggle {
    font-size: 75%;
    padding: 8%;
}

.react-calendar-heatmap text {
    font-size: 0.45rem;
    font-weight: 500;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 0.5rem;
}

/* 
===== Data Tables =====
*/
.react-bootstrap-table {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 1rem;
}
.react-bootstrap-table th {
    color: #8898aa;
    background-color: #f6f9fc;
}

.dataTables_filter {
    font-weight: 400;
    white-space: nowrap;
    text-align: left;
    float: left;
}
.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
}

.react-bootstrap-table .table {
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #e9ecef;
}
.react-bootstrap-table-pagination .dataTables_length label {
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.react-bootstrap-table-pagination .dataTables_length {
    display: inline-block;
}

.dataTables_info, .dataTables_length, .dt-buttons {
    padding-left: 1.5rem;
}

.react-bootstrap-table-pagination .dataTables_length, .react-bootstrap-table-pagination .react-bootstrap-table-pagination-total {
    font-size: .875rem;
}

.react-bootstrap-table-pagination .dataTables_length select.form-control-sm {
    height: calc(1.8125rem + 6px);
}

.react-bootstrap-table-pagination .dataTables_length select {
    width: auto;
    display: inline-block;
}

.dataTables_length .form-control {
    margin: 0 0.375rem;
}

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .pagination {
    justify-content: flex-end;
    padding-right: 1.5rem;
}

/* 
    ===== Table Column Header Carets/Dropdown Icons -- Fix/Workaround =====
    React-bootstrap-table-next has reported issues on adding caret/dropdown icons in the titles of the header columnns.
    The styling below is a workaround for this until support is provided or a better solution is found.
*/
th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .order-4:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .caret-4-desc:after {
    content: "\2193";
}
/*
    ===============================================
*/